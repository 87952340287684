/* eslint-disable */
import $ from 'jquery'

// eslint-disable-next-line no-unused-vars
(function (document, jQuery, undefined) {
  const tablist = document.querySelectorAll('[role="tablist"]')[0],
    delay = determineDelay()
  let tabs, panels

  generateArrays()

  function generateArrays () {
    tabs = document.querySelectorAll('[role="tab"]')
    panels = document.querySelectorAll('[role="tabpanel"]')
  }

  // For easy reference
  const keys = {
    end:    35,
    home:   36,
    left:   37,
    up:     38,
    right:  39,
    down:   40,
    delete: 46,
  }

  // Add or substract depending on key pressed
  const direction = {
    37: -1,
    38: -1,
    39: 1,
    40: 1,
  }

  // Bind listeners
  for (let i = 0; i < tabs.length; ++i) {
    addListeners(i)
  }

  function addListeners (index) {
    tabs[index].addEventListener('click', clickEventListener)
    tabs[index].addEventListener('keydown', keydownEventListener)
    tabs[index].addEventListener('keyup', keyupEventListener)

    // Build an array with all tabs (<button>s) in it
    tabs[index].index = index
  }

  // When a tab is clicked, activateTab is fired to activate it
  function clickEventListener (event) {
    const tab = event.target
    activateTab(tab, false)
  }

  // Handle keydown on tabs
  function keydownEventListener (event) {
    const key = event.keyCode

    switch (key) {
      case keys.end:
        event.preventDefault()
        // Activate last tab
        activateTab(tabs[tabs.length - 1])
        break
      case keys.home:
        event.preventDefault()
        // Activate first tab
        activateTab(tabs[0])
        break

      // Up and down are in keydown
      // because we need to prevent page scroll >:)
      case keys.up:
      case keys.down:
        determineOrientation(event)
        break
    }
  }

  // Handle keyup on tabs
  function keyupEventListener (event) {
    const key = event.keyCode

    switch (key) {
      case keys.left:
      case keys.right:
        determineOrientation(event)
        break
      case keys.delete:
        determineDeletable(event)
        break
    }
  }

  // When a tablistâ€™s aria-orientation is set to vertical,
  // only up and down arrow should function.
  // In all other cases only left and right arrow function.
  function determineOrientation (event) {
    const key = event.keyCode
    const vertical =
      tablist.getAttribute('aria-orientation') === 'vertical'
    let proceed = false

    if (vertical) {
      if (key === keys.up || key === keys.down) {
        event.preventDefault()
        proceed = true
      }
    } else if (key === keys.left || key === keys.right) {
      proceed = true
    }

    if (proceed) {
      switchTabOnArrowPress(event)
    }
  }

  // Either focus the next, previous, first, or last tab
  // depening on key pressed
  function switchTabOnArrowPress (event) {
    const pressed = event.keyCode

    for (let x = 0; x < tabs.length; x++) {
      tabs[x].addEventListener('focus', focusEventHandler)
    }

    if (direction[pressed]) {
      const target = event.target
      if (target.index !== undefined) {
        if (tabs[target.index + direction[pressed]]) {
          tabs[target.index + direction[pressed]].focus()
        } else if (pressed === keys.left || pressed === keys.up) {
          focusLastTab()
        } else if (pressed === keys.right || pressed === keys.down) {
          focusFirstTab()
        }
      }
    }
  }

  // Activates any given tab panel
  function activateTab (tab, setFocus) {
    setFocus = setFocus || true
    // Deactivate all other tabs
    deactivateTabs()

    // Remove tabindex attribute
    tab.removeAttribute('tabindex')

    // Set the tab as selected
    tab.setAttribute('aria-selected', 'true')

    // Get the value of aria-controls (which is an ID)
    const controls = tab.getAttribute('aria-controls')

    // Remove hidden attribute from tab panel to make it visible
    document.getElementById(controls).removeAttribute('hidden')

    if (controls === 'tab-map') {
      showMap(markersServer)
    } else {
      clearMarkers()
    }

    // Set focus when required
    if (setFocus) {
      tab.focus()
    }
  }

  // Deactivate all tabs and tab panels
  function deactivateTabs () {
    for (let t = 0; t < tabs.length; t++) {
      tabs[t].setAttribute('tabindex', '-1')
      tabs[t].setAttribute('aria-selected', 'false')
      tabs[t].removeEventListener('focus', focusEventHandler)
    }

    for (let p = 0; p < panels.length; p++) {
      panels[p].setAttribute('hidden', 'hidden')
    }
  }

  // Make a guess
  function focusFirstTab () {
    tabs[0].focus()
  }

  // Make a guess
  function focusLastTab () {
    tabs[tabs.length - 1].focus()
  }

  // Detect if a tab is deletable
  function determineDeletable (event) {
    const target = event.target

    if (target.getAttribute('data-deletable') !== null) {
      // Delete target tab
      deleteTab(event, target)

      // Update arrays related to tabs widget
      generateArrays()

      // Activate the closest tab to the one that was just deleted
      if (target.index - 1 < 0) {
        activateTab(tabs[0])
      } else {
        activateTab(tabs[target.index - 1])
      }
    }
  }

  // Deletes a tab and its panel
  function deleteTab (event) {
    const target = event.target
    const panel = document.getElementById(
      target.getAttribute('aria-controls')
    )

    target.parentElement.removeChild(target)
    panel.parentElement.removeChild(panel)
  }

  // Determine whether there should be a delay
  // when user navigates with the arrow keys
  function determineDelay () {
    const hasDelay = tablist.hasAttribute('data-delay')
    // eslint-disable-next-line no-shadow
    let delay = 0

    if (hasDelay) {
      const delayValue = tablist.getAttribute('data-delay')
      if (delayValue) {
        delay = delayValue
      } else {
        // If no value is specified, default to 300ms
        delay = 300
      }
    }

    return delay
  }

  //
  function focusEventHandler (event) {
    const target = event.target

    setTimeout(checkTabFocus, delay, target)
  }

  // const l18n_js_map_vars = l18n_js_map_vars;
  // console.log(l18n_js_map_vars)
  // eslint-disable-next-line no-undef
  const markersServer = l18n_js_map_vars.markers
  // eslint-disable-next-line no-undef
  // const moreLink = l18n_js_map_vars.moreLink;
  // eslint-disable-next-line no-unused-vars
  let geocoder, map, oms, bounds, markerCluster, infowindow
  // eslint-disable-next-line no-undef
  // const types = l18n_js_map_vars.types;

  let markers = []
  let allMarkers = []
  let icon = {}
  if (window.google) {
    icon = {
      // eslint-disable-next-line no-undef
      url: `${l18n_js_map_vars.icon}`,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(40, 50), // scaled size
      // eslint-disable-next-line no-undef
      labelOrigin: new google.maps.Point(20, 18),
      // origin: new google.maps.Point(0, 0), // origin
      // anchor: new google.maps.Point(0, 10), // anchor
    }
  }

  // eslint-disable-next-line no-undef
  const cluster = l18n_js_map_vars.cluster
  const styles = [
    [
      {
        url:       cluster,
        height:    60,
        width:     40,
        anchor:    [10, 0],
        textColor: '#ebe9e5',
        textSize:  15,
      },
    ],
  ]

  const getSelectionValues = async () => {
    const filtersInput = $('[rel="area-filter"]')
    let filters = []
    filtersInput.on('click', async function () {
      if ($(this).hasClass('parent')) {
        const inputChildren = $(this)
          .parents('li')
          .children('ul')
          .find('.child')
        filters.push($(this).val())
        inputChildren.prop('checked', true)
        // eslint-disable-next-line array-callback-return
        inputChildren.map((index, item) => {
          filters.push($(item).val())
        })
        if (!$(this).is(':checked')) {
          const childrens = $(this)
            .parents('li')
            .children('ul')
            .find('.child')
          const toBeRemoved = []
          childrens.prop('checked', false)
          toBeRemoved.push($(this).val())
          // eslint-disable-next-line array-callback-return
          childrens.map((childIndex, child) => {
            toBeRemoved.push($(child).val())
          })

          const newFilters = filters.filter(
            (item) => !toBeRemoved.includes(item)
          )
          filters = [...new Set(newFilters)]
        }
      } else {
        const parent = $(this)
          .parents('li')
          .find('.parent')

        const parentUl = $(this)
          .parentsUntil('.list-parent')
          .get(2)
        // const parentLiCheckBox = $(parentUl)
        // 	.parent()
        // 	.find('input[type="checkbox"].parent');
        const siblingsCheckoxes = $(parentUl)
          .parent()
          .find('input[type="checkbox"]:not(.parent)')
        const siblingsCheckoxesChecked = $(parentUl)
          .parent()
          .find('input[type="checkbox"]:not(.parent):checked')

        if (
          siblingsCheckoxesChecked.length === siblingsCheckoxes.length
        ) {
          parent.prop('checked', true)
        } else {
          parent.prop('checked', false)
        }

        filters = filters.filter((item) => {
          return item !== parent.val()
        })
        if ($(this).is(':checked')) {
          filters.push($(this).val())
        } else {
          filters = filters.filter((item) => {
            return item !== $(this).val()
          })
        }
      }

      filterCompaniesCard(filters)
      //Add here the map with filter
      filterMarkers(filters)
    })
  }

  showMap(markersServer)

  const filterCompaniesCard = async (filters) => {
    const companies = $('.company-card')
    companies.fadeOut()
    const indexes = []
    companies.map((companyIndex) => {
      const areas = $(companies[companyIndex])
        .data('areas')
        .split(',')
        .map(function (item) {
          return item.trim()
        })

      // eslint-disable-next-line no-unused-vars,array-callback-return
      return filters.some((elem) => {
        if (areas.includes(elem)) {
          $(companies[companyIndex]).fadeIn()
          indexes.push(companyIndex)
        }
      })
    })
    // console.log('filtered companies', companies);
    // console.log('indexes', indexes);
    await setMargins(indexes)
    if (!filters.length) {
      // await setMargins();
      $(companies).fadeIn()
      // return;
    }
    // console.log('Filters', filters);
  }

  const setMargins = (indexes = []) => {
    const indexesString = []
    $.each(indexes, function (index, value) {
      const str = ':eq(' + value + ')'
      indexesString.push(str)
    })

    // console.log('indexesString', indexesString);
    //
    // //$('.company-card').filter(':eq(0), :eq(1), :eq(5)');
    //
    // console.log(
    // 	'selected?',
    // 	$('.company-card').filter(indexesString.join(', '))
    // );

    let visibleCompanies
    if (indexesString.length) {
      visibleCompanies = $('.company-card').filter(
        indexesString.join(', ')
      )
    } else {
      visibleCompanies = $('.company-card')
    }

    // const mediaQuery1536 = window.matchMedia('(min-width: 1536px)');
    // const mediaQuery1800 = window.matchMedia('(min-width: 1800px)');
    let columns = 3

    if (matchMedia) {
      const mediaQuery600 = window.matchMedia('(min-width: 600px)')
      const mediaQuery1024 = window.matchMedia('(min-width: 1024px)')
      // const mediaQuery1536 = window.matchMedia('(min-width: 1536px)');
      if (mediaQuery600.matches) {
        columns = 2
      }
      if (mediaQuery1024.matches) {
        columns = 3
      }
      // if (mediaQuery1536.matches) {
      // 	columns = 3;
      // }
    }
    // eslint-disable-next-line array-callback-return
    visibleCompanies.map((index, item) => {
      // console.log('computed style', window.getComputedStyle(item));
      // console.log('index', index);
      // console.log('columns', columns);
      // console.log('item', item);
      if (index % columns === 0 || index === 0) {
        $(item).css({marginLeft: 0})
      } else {
        $(item).css({marginLeft: '1em'})
      }
    })
  }

  //@todo Filter Markers
  // eslint-disable-next-line no-unused-vars
  const filterMarkers = (filters) => {
    // console.log('filters', filters);
    clearMarkers()
    let newMarkers = []
    newMarkers = filters.length
      ? markersServer.filter((item) => {
        const itemCats = item.company_wf
        // .some add markers to markers array
        // .every filter markers to be included into all types
        // eslint-disable-next-line no-unused-vars
        return filters.some((elem, index, arr) => {
          return itemCats.includes(elem)
        })
      })
      : markersServer

    // setMargins();
    loadMarkers(newMarkers)
  }

  function reloadOnCookies () {
    const cookieConsentMapBox = document.getElementById('locations-map')
    const cookiesMessageBox = cookieConsentMapBox.querySelector(
      '[rel="cookies-box"]'
    )
    const cookiesLink = cookieConsentMapBox.querySelector(
      '[rel="accept-cookie"]'
    )

    setTimeout(function () {
      document
        .querySelector('.ccm--save-settings')
        .addEventListener('click', () => {
          location.reload()
        })
    }, 200)
    // console.log('cookiesLink', cookiesLink);
    if (cookiesLink) {
      setTimeout(function () {
        cookiesMessageBox.style.display = 'block'
        cookiesLink.addEventListener('click', () => {
          const consentButton = document.querySelector(
            '.ccm-settings-summoner--link'
          )
          consentButton.click()
        })
      }, 500)
    }
  }

  reloadOnCookies()

  function showMap (markersArr) {
    if (!window.google) return false
    // eslint-disable-next-line no-undef
    bounds = new window.google.maps.LatLngBounds()
    // eslint-disable-next-line no-undef
    geocoder = new window.google.maps.Geocoder()
    // eslint-disable-next-line no-undef
    infowindow = new window.google.maps.InfoWindow({
      maxWidth: 350,
    })
    // eslint-disable-next-line no-undef
    map = new window.google.maps.Map(
      document.getElementById('locations-map'),
      {
        // center: new google.maps.LatLng(46.8095954, 7.103216), //commented
        // center: { lat: 46.52863469527167, lng: 2.43896484375 },
        // center:      googleLatLong,
        noWrap:      true,
        scrollwheel: false,
        zoom:        9,
        // eslint-disable-next-line no-undef
        mapTypeId:          window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl:     false,
        streetViewControl:  false,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
        styles:             [
          {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers:     [{visibility: 'on'}, {saturation: -100}],
          },
          {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers:     [{visibility: 'on'}, {saturation: -100}],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers:     [{saturation: -100}, {weight: 1.2}],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers:     [{saturation: -100}],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers:     [{saturation: -100}],
          },
        ],
      }
    )

    // eslint-disable-next-line no-undef
    oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove:      true,
      markersWontHide:      true,
      basicFormatEvents:    true,
      keepSpiderfied:       true,
      circleFootSeparation: 50,
      nearbyDistance:       30,
    })

    markersArr.forEach(function (marker) {
      loadMarkers(marker, oms)
      // eslint-disable-next-line no-undef
      const listener = google.maps.event.addListener(
        map,
        'idle',
        function () {
          showVisibleMarkers()
          if (marker.length === 1) {
            map.setZoom(8)
          }
          // eslint-disable-next-line no-undef
          google.maps.event.removeListener(listener)
        }
      )
    })
  }

  function showVisibleMarkers () {
    // bounds = map.getBounds();
    bounds = new google.maps.LatLngBounds()
    // console.log('All markers', allMarkers)
    for (let i = 0; i < allMarkers.length; i++) {
      const boundMarker = allMarkers[i]
      // console.log('newMarker.getPosition()', newMarker.getPosition());
      // console.log('newMarker.position', newMarker.position.lat());
      if (bounds.contains(boundMarker.getPosition()) === true) {
        // console.log('postion', position);
        bounds.extend(boundMarker.position)
        // bounds.extend(position);
      }
      map.fitBounds(bounds)
    }
    map.panToBounds(bounds)
  }

  // eslint-disable-next-line no-shadow
  function loadMarkers (markersArray, oms) {
    // console.log(markersArray);
    markersArray.forEach(function (markerItem) {
      // console.log('markerItem', markerItem);
      (function () {
        let logo = ''
        if (markerItem.company_logo) {
          const imageExtension = markerItem.company_logo
              .split('.')
              .pop(),
            imageUrl = markerItem.company_logo.split(
              `.${imageExtension}`
            ),
            retinaImage = `${imageUrl[0]}@2x.${imageExtension}`
          logo = `<img alt="${markerItem.company_name}" srcset="${markerItem.company_logo}, ${retinaImage} 2x" loading="lazy">`
        } else {
          logo = `<img src="${markerItem.company_logo}" alt="${markerItem.company_name}" />`
        }
        const pdf = markerItem.company_person_url
          ? `<li><a href="${markerItem.company_person_url}" target="_blank">${markerItem.company_person_url_txt}</a></li>`
          : ''
        // const driving_dir_lnk = `<li><a href="https://www.google.com/maps/dir/?api=1&destination=${markerItem.company_address},${markerItem.company_city},CH&travelmode=driving" title="Anfahrt" target="_blank">Anfahrt</a></li>`;
        const driving_dir_lnk = markerItem?.company_gmap_url
          ? `<li><a href="${markerItem?.company_gmap_url}" title="Anfahrt" target="_blank">Anfahrt</a></li>`
          : ''
        // var infowindowcontent = `<!--<div class="marker-item"><img src="${markerItem.project_thumb}" alt="${markerItem.project_title}" /><span>${markerItem.project_title}</span><a href="${markerItem.project_url}">${moreLink}</a></div>-->`;
        const infoWindowContent = `<div class="marker-item"><div class="marker-item-visual">${logo}</div>
<div class="marker-item-details">
<span>${markerItem.company_name}</span>
<ul>
<li>${markerItem.company_address}, ${markerItem.company_zip}, ${
          markerItem.company_city
        } </li>
<li>Tel. <a href="tel:${markerItem.company_phone.replace(
          /\s/g,
          ''
        )}" itemprop="telephone">${markerItem.company_phone}</a> </li>
<li><a href="mailto:${
          markerItem.company_email
        }" title="Click to e-mail us" itemprop="email">${
          markerItem.company_email
        }</a></li><li><a href="${markerItem.company_site}">${
          markerItem.company_site
            .replace(/^(?:https?:\/\/)/i, '')
            .split('/')[0]
        }</a></li>${pdf}${driving_dir_lnk}</ul></div></div>`
        // eslint-disable-next-line no-undef
        const latLng = new google.maps.LatLng(
          +markerItem.company_lat,
          +markerItem.company_lng
        )

        let howManyWithSameCoords = []
        for (let i = 0; i < markersArray.length; i++) {
          if (
            markerItem.company_lat ===
            markersArray[i].company_lat &&
            markerItem.company_lng === markersArray[i].company_lng
          ) {
            howManyWithSameCoords++
          }
        }

        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: latLng,
          map,
          icon,
          title:    markerItem.company_city,
          // eslint-disable-next-line no-undef
          animation: google.maps.Animation.DROP,
          label:     {
            text:
                        howManyWithSameCoords > 1
                          ? howManyWithSameCoords.toString()
                          : ' ',
            color:      '#ff0000',
            fontWeight: 'bold',
          },
        })

        bounds.extend(marker.position) // commented 18.02

        markers.push(marker)

        allMarkers.push(marker)
        infowindow.set('closed', true)
        // console.log(allMarkers);
        //added

        geocoder.geocode(
          {
            // location: latLng,
            componentRestrictions: {
              country: 'CH',
            },
          },
          function (results, status) {
            // eslint-disable-next-line no-undef
            if (status === 'OK') {
              bounds.extend(results[0].geometry.location) //moved from  below
              if (results[0].geometry.viewport) {
                map.setCenter(results[0].geometry.location)
                map.fitBounds(results[0].geometry.viewport, {
                  top:  200,
                  left: -120,
                })

                map.setZoom(10)
              }
            }
          }
        )
        //added

        // eslint-disable-next-line no-undef
        google.maps.event.addListener(
          marker,
          'spider_click',
          function () {
            map.panTo(marker.getPosition())
            map.panBy(0, -158)
            infowindow.setContent(infoWindowContent)
            infowindow.open(map, marker)
            infowindow.setZIndex(10000)

            // eslint-disable-next-line no-undef
            google.maps.event.addListener(map, 'click', function () {
              infowindow.close()
            })

            for (let i = 0; i < markers.length; i++) {
              markers[i].set('label', '')
            }
          }
        )

        oms.addMarker(marker)
      })()
    })

    // eslint-disable-next-line no-undef
    markerCluster = new MarkerClusterer(map, markers, {
      gridSize:       40,
      maxZoom:        7,
      minClusterSize: 2,
      styles:         styles[0],
    })

    map.fitBounds(bounds)
  }

  function clearMarkers () {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null)
    }
    // eslint-disable-next-line no-undef
    if (markerCluster instanceof MarkerClusterer) {
      markerCluster.clearMarkers()
    }
    markers = []
  }

  getSelectionValues()
})(document, $)